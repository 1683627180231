import React from "react"

import Layout from "components/layout/layoutMain"
import SEO from "components/Seo"
import Homepage from "pages/homepage"
import HomepageNew from "pages/homepage-new"
import Header from "components/Header"
import Footer from "components/Footer"
import { isLpgDemergerOn } from "constants/global"


const IndexPage = () => (
  <>
    <SEO title="Kleenheat" />
    <Header />
    <Layout>
      {isLpgDemergerOn && <HomepageNew />}
      {!isLpgDemergerOn && <Homepage />}
    </Layout>
    <Footer />
  </>
)

export default IndexPage
